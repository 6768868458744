import React from "react";
import Layout from "../../components/layouts/main-layout";
import Container from "../../components/container";
import { StaticImage } from "gatsby-plugin-image";
import CourseInfo from "../../components/course-info";
import FaqSection from "../../components/faqsection";
import Seo from "../../components/seo";

const Privatunterricht = () => {
  return (
    <Layout>
      <Seo
        title="Privat Surfunterricht für Anfänger und Fortgeschrittene"
        description="Du bist auf der Suche nach intensiver Betreuung? Wir bieten Privat kurse für alle Surf Sportarten auf der Nordseeinsel Langeoog an."
      />
      <StaticImage
        src="../../images/heroimages/tidens_website_privatstunde_header.jpg"
        alt="Privatunterricht bei Tidens Surfhuus"
        width={2048}
        height={680}
        quality={90}
        className="w-full h-[35vw] min-h-[25vw] max-h-[50vh]"
        objectFit="cover"
      />
      <div className="bg-secondary rounded-t-3xl relative top-[-1.5rem]">
        <Container>
          <h1>Privatunterricht</h1>
          <p>
            Du möchtest etwas ganz Bestimmtes lernen, brauchst einen Surfbuddy
            oder bist einfach nur auf der Suche nach intensiver Betreuung? Gerne
            unterrichten wir dich auch in einer Privatstunde. Bitte beachte,
            dass wir Privatstunden nur auf Anfrage anbieten können. In der
            Hauptsaison haben wir neben den normalen Kursen leider kaum Zeit für
            Privatunterricht. In der Nebensaison (Frühjahr/Herbst) ist die
            Wahrscheinlichkeit höher, dass wir dich auch privat betreuen können.
          </p>
          <h3 className="pt-8">Kursangebot</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
            <CourseInfo
              title="Privatstunde"
              price="99"
              duration="1 Stunde"
              people="1"
              description="In der Privatstunde hast du eine Trainerin / einen Trainer ganz für dich allein. Der Kursinhalt wird individuell auf deine Bedürfnisse und Wünsche zugeschnitten und im Vorfeld mit dir besprochen. "
              requestOnly={true}
            />
          </div>
          <h3>Häufige Fragen</h3>
          <FaqSection sport="allgemein" />
        </Container>
      </div>
    </Layout>
  );
};

export default Privatunterricht;
